<template>
	<form class="form form--agreement agreement" @submit.prevent="submitForm(fields)">
		<div class="form__loader" v-if="isLoading">
			<loading-spinner />
		</div>

		<div class="form-section">
			<div class="form-row form-row--col-2">
				<text-field class="form__field" label="Title" v-model="fields.title" :required="true" />
				<select-field class="form__field" label="Status" v-model="fields.status" :options="agreementStatus" placeholder="Select Status..." :required="true" />
			</div>
			<div class="form-row">
				<text-area class="form__field" label="Content" v-model="fields.content" :required="true" />
			</div>

			<div class="form__actions form__actions--horizontal">
				<mdc-button class="mdc-button--primary mdc-button--unelevated form__action" type="submit" :disabled="isLoading">
					{{ fields.agreementId ? 'Update' : 'Create' }} Agreement
				</mdc-button>

				<mdc-button class="mdc-button--error form__action" type="button" :disabled="isLoading" @click="remove(fields)" v-if="fields.agreementId && fields.status != 'removed'">
					Remove Agreement
				</mdc-button>
			</div>
		</div>

	</form>
</template>

<script>
import Checkbox from '@/components/Checkbox'
import LoadingSpinner from '@/components/LoadingSpinner'
import SelectField from '@/components/SelectField'
import TextArea from '@/components/TextArea'
import TextField from '@/components/TextField'
import { AgreementStatus } from '@/lib/enums'
import MdcButton from '@/components/MdcButton'

export default {
	title: 'FormAgreement',
		components: {
		Checkbox,
		LoadingSpinner,
		MdcButton,
		SelectField,
		TextArea,
		TextField,
	},
	props: {
		agreement: {
			type: Object,
			default: () => ({}),
		},
	},
	data: () => ({
		defaults: {
			status: 'active',
		},
		fields: {},
	}),
	computed: {
		isLoading() {
			return this.$store.getters['misc/loading']
		},
		error() {
			return this.$store.getters['misc/error']
		},
		agreementStatus() {
			return AgreementStatus.toDropdown()
		},
	},
	mounted() {
		this.$store.dispatch('client/list')
	},
	methods: {
		async submitForm(fields) {
			if (fields.agreementId) {
				try {
					const { agreementId } = fields
					await this.$store.dispatch('agreement/update', { agreementId, data: fields, } )
					this.$notice(`Agreement updated.`)
				} catch (error) {
					this.$notice(`ERROR: ${error.message || error}`)
					console.error(error)
				}
			} else {
				try {
					const res = await this.$store.dispatch('agreement/create', fields)
					this.$notice(`Agreement created.`)
					this.$router.push({ title: 'agreement', params: { agreementId: res.agreementId, }})
				} catch (error) {
					this.$notice(`ERROR: ${error.message || error}`)
				}
			}
		},
	},
	watch: {
		agreement: {
			immediate: true,
			handler: function(props) {
				this.fields = {
					...this.defaults,
					...this.fields,
					...props,
					...{
						locationId: this.$route.params.locationId,
					}
				}
			},
		},
	},
}
</script>

<style scoped lang="scss">

</style>
