<template>
	<div class="modal modal--agreement modal-agreement">
		<div class="modal-agreement__form">
			<form-agreement :agreement="agreement" />
		</div>
		<div class="modal-agreement__stats">
			<dl>
				<dt>Location</dt>
				<dd>{{ location.name }}</dd>
				<dt>Date Created</dt>
				<dd>{{ agreement.dateCreated | date('PPp') }}</dd>
				<dt>Created By</dt>
				<dd>{{ agreement.createdByName }}</dd>
				<dt>Date Modified</dt>
				<dd>{{ agreement.dateModified | date('PPp') }}</dd>
				<dt>Modified By</dt>
				<dd>{{ agreement.modifiedByName }}</dd>
			</dl>

		</div>
	</div>
</template>

<script>
import FormAgreement from '@/components/FormAgreement'

export default {
	name: 'ModalAgreement',
	components: {
		FormAgreement,
	},
	mounted() {
		const { agreementId, locationId } = this.$route.params
		if (agreementId && locationId) {
			this.$store.dispatch('agreement/get', { agreementId, locationId, })
			this.$store.dispatch('location/get', locationId )
		}
	},
	destroyed() {
		this.$store.dispatch('agreement/unset')
	},
	computed: {
		isLoading() {
			return this.$store.getters['misc/loading']
		},
		agreement() {
			return this.$store.getters['agreement/agreement']
		},
		location() {
			return this.$store.getters['location/location']
		},
	},
	methods: {
	},
}
</script>

<style scoped lang="scss">
.modal-agreement {
	display: grid;
	grid-template-columns: 2fr minmax(10rem, 1fr);
	min-width: 70vw;
	min-height: 50vh;
	width: 100%;

	@include modules.media-query-max-width('desktop-xs') {
		grid-template-columns: 1fr;
	}

	dl {
		@include modules.fontSize(14px);
		@include modules.gutter('grid-row-gap', 0.5);
		display: grid;
		grid-column-gap: 0;
		grid-template-columns: 1fr auto;
	}

	dd {
		margin: 0;
		text-align: right;
		padding: 0.5rem;

		&:nth-of-type(odd) {
			background-color: modules.color_('background', 'light');
		}
	}

	dt {
		font-weight: 500;
		padding: 0.5rem;
		text-transform: uppercase;

		&:nth-of-type(odd) {
			background-color: modules.color_('background', 'light');
		}
	}
}
</style>
